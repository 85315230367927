import React, {useMemo} from 'react';
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {Button, Icon, Popup} from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import {addGlobalMessageAtom} from '../../store/globalMessage';
import {useSetAtom} from 'jotai';
import CustomTable from "../../components/CustomTable";
import {useUserInfo} from "../../hooks/useAuth";

function ImagesTable(props) {
	const {userdata, multitenant} = useUserInfo();
	const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
	const genericImages = useMemo(() => props.genericImages, [props.genericImages]);

	const column = [
		{
			id: 'name',
			Header: 'Name',
			accessor: 'name',
		},
		{
			id: 'version',
			Header: 'Version',
			accessor: 'version',
		},
		{
			id: 'description',
			Header: 'Description',
			accessor: 'description',
		},
		{
			id: 'architecture',
			Header: 'Architecture',
			accessor: 'arch',
		},
		{
			id: 'type',
			Header: 'Type',
			accessor: 'type',
		},
	];

	const columns = useMemo(() => column, [props.tenantImages]); // eslint-disable-line

	const generateImage = (imageUuid) => NMService.generateImage(userdata.tenantid, imageUuid).then(() =>
		addGlobalMessage({
			header: 'Image generation has started!',
			content: 'Image generation has successfully started.',
			type: 'positive',
		})).catch(e => null)
		.finally(() => props.refetchTenantImages())

	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => [
			...columns,
			{
				Header: () => null,
				id: 'actions',
				Cell: ({row}) => {
					const rowImg = row.original;

					const generatedImg = props.tenantImages.find(img => img.name === rowImg.name && img.version === rowImg.version && img.arch === rowImg.arch);
					let iconStatus = null;
					if (generatedImg) {
						switch (generatedImg.status) {
							case "NONE":
							case "SIGNING":
							case "CREATING":
								iconStatus = <Popup
									content='Creating image'
									on={'hover'}
									wide
									position="right center"
									style={{textAlign: "center"}}
									trigger={<Icon
										name="hourglass half"
										color="yellow"
										style={{margin: 0, padding: 0}}
										size="large"
									/>}
								/>
								break;
							case "UPLOADING":
								iconStatus = <Popup
									content='Uploading image'
									on={'hover'}
									wide
									position="right center"
									style={{textAlign: "center"}}
									trigger={<Icon
										name="cloud upload"
										color="blue"
										style={{margin: 0, padding: 0}}
										size="large"
									/>}
								/>
								break;
							case "CREATED":
								iconStatus = <>
									{/*<Popup*/}
									{/*    content='Image created successfully'*/}
									{/*    on={'hover'}*/}
									{/*    wide*/}
									{/*    position="right center"*/}
									{/*    style={{textAlign: "center"}}*/}
									{/*    trigger={<Icon*/}
									{/*        name="checkmark box"*/}
									{/*        color="green"*/}
									{/*        style={{margin: 0, padding: 0}}*/}
									{/*        size="large"*/}
									{/*    />}*/}
									{/*/>*/}
									<Popup
										content='Download image'
										on={'hover'}
										wide
										position="right center"
										style={{textAlign: "center"}}
										trigger={<a href={generatedImg.location} target="_blank" rel="noopener noreferrer">
											<Button
												circular
												icon="download"
											/>
										</a>}
									/>
									<Popup
										content='Download image signature'
										on={'hover'}
										wide
										position="right center"
										style={{textAlign: "center"}}
										trigger={<a href={generatedImg.signature} target="_blank" rel="noopener noreferrer">
											<Button
												circular
												icon="file alternate"
											/>
										</a>}
									/>
									<Popup
										content='Copy hash to clipboard'
										on={'hover'}
										wide
										position="right center"
										style={{textAlign: "center"}}
										trigger={<Button
											circular
											icon="pencil alternate"
											onClick={() => navigator.clipboard.writeText(generatedImg.hash)
												.then(() => {
													addGlobalMessage({
														header: 'Hash has been copied!',
														content: 'Hash has been successfully copied to clipboard.',
														type: 'positive',
													});
												})
												.catch(err => {
													addGlobalMessage({
														header: 'Unable to copy hash!',
														content: 'Unknown error occurred while trying to copy hash to clipboard.',
														type: 'warning',
													});
												})}
										/>}
									/>
								</>

								break;
							case "ERROR":
							default:
								iconStatus = <Popup
									content='Error occured while creating image'
									on={'hover'}
									wide
									position="right center"
									style={{textAlign: "center"}}
									trigger={<Icon
										name="exclamation triangle"
										color="red"
										style={{margin: 0, padding: 0}}
										size="large"
									/>}
								/>
								break;
						}
					}

					return (
						<div style={{textAlign: 'center'}}>
							{generatedImg ? (
								<>
									{iconStatus}
								</>
							) : (

								<Popup
									content='Generate image'
									on={'hover'}
									wide
									position="right center"
									style={{textAlign: "center"}}
									trigger={<Button
										icon="play"
										//color="red"
										onClick={() => generateImage(rowImg.uuid)}
										circular

									/>}
								/>
							)}
						</div>)
				},
				disableGlobalFilter: true,
				disableFilters: true,
				width: 2,
			},
		]);
	};

	return (
		<>
			<CustomTable
				data={genericImages}
				columns={columns}
				tableHooks={tableHooks}
			/>
		</>
	);
}

export default (ImagesTable);